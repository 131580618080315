@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 56px;
  padding: 0 12px 0 16px;
  background-color: $bottombar-color;
  position: relative;

  > .side {
    flex: 1;
    display: flex;
    flex-direction: row;

    &.left {
      align-items: center;

      > img {
        width: 32px;
      }
    }
    &.right {
      justify-content: flex-end;
      gap: 80px;
    }
  }
  > .center {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
}
