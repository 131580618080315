@import '@/styles/common.scss';

.container {
  > .button {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 12px;
    color: lightgray;
    gap: 6px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: white;
      background-color: darken($topbar-color, 5%);
    }
    &.active {
      color: $tint-color;
    }
    > .label {
      font-size: 12px;
    }
  }
}
