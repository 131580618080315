@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 56px);
  padding-top: 8px;
  padding-bottom: 8px;
  gap: 8px;
  overflow-y: auto;
  scrollbar-width: none;
  pointer-events: auto;
}
