@import '@/styles/common.scss';

.container {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  border: 2px solid $tint-color;
  background: linear-gradient(darken($field-color, 4%) 0%, darken($field-color, 16%) 100%);

  > .label {
    color: gray;
    font-size: 14px;
  }
  > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: 3px;
    border-radius: 50%;
    background-color: $tint-color;
    cursor: pointer;
    transition: background-color 0.2s;
    touch-action: none;

    &:hover {
      background-color: darken($tint-color, 5%);
    }
  }
}
