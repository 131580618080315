@import '@/styles/common.scss';

.container {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  width: fit-content;
  pointer-events: auto;

  &.targetable {
    cursor: pointer;
  }
  > .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    overflow: hidden;
    background-color: rgba($button-pressed-color, 0.9);
    transition: width 0.2s;

    &.target {
      width: 32px;
    }
  }
  > .box {
    position: relative;
    width: 320px;
    padding: 12px;
    box-sizing: border-box;
    background-color: rgba(#111111, 0.9);

    > .color {
      position: absolute;
      top: 0;
      left: 50%;
      width: 80px;
      height: 4px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      transform: translateX(-50%);
      transition: height 0.2s;

      &.clickable:hover {
        height: 6px;
        cursor: pointer;
      }
    }
    > .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      > .info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        > .label {
          display: flex;
          align-items: center;
          padding: 0 4px;
          height: 16px;
          color: white;
          font-size: 10px;
          border-radius: 4px;
          border: 1px solid $line-color;
        }
      }
    }
    > .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-right: -8px;

      > .thumbnail {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        overflow: hidden;
        filter: grayscale(100%) brightness(0.5);

        &.active {
          width: 40px;
          height: 40px;
          filter: none;
        }
      }
      > .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;

        > .name {
          color: gray;
          font-size: 14px;

          &.active {
            color: white;
          }
        }
      }
    }
    > .body {
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s;

      &.open {
        max-height: 1440px;
        transition: all 0.4s;
      }
      > .waiting {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding: 20px 0;
        border-radius: 4px;
        background-color: rgba(white, 0.05);

        > .title {
          color: white;
          font-size: 14px;
        }
        > .message {
          color: gray;
          font-size: 12px;
          text-align: center;
          line-height: 1.4;
        }
        > .button {
          @include button;
          margin-top: 8px;
          height: 28px;
          padding: 0 8px;
          font-size: 12px;
        }
        > .sliderWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          > .cancel {
            @include button;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            box-shadow: none;
          }
        }
      }
    }
    > .bottom {
      margin-top: 8px;
    }
  }
}
