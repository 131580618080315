@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 200;

  &.success {
    background-color: rgba(darken(green, 10%), 0.9);
  }
  &.error {
    background-color: rgba(darken(red, 10%), 0.9);
  }
  > .content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    > .text {
      color: white;
      font-size: 14px;
    }
    > .close {
      position: absolute;
      top: 50%;
      right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  > .progress {
    height: 4px;
    animation: progressBar linear;

    &.success {
      background-color: rgba(green, 0.9);
    }
    &.error {
      background-color: rgba(red, 0.9);
    }
  }
}

@keyframes progressBar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
