@import '@/styles/common.scss';

.container {
  position: fixed;
  border-radius: 4px;
  background-color: rgba(#111111, 0.9);
  z-index: 30;
  display: none;

  &.show {
    display: block;
  }
  > .color {
    position: absolute;
    top: 0;
    left: 50%;
    width: 80px;
    height: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    transform: translateX(-50%);
  }
  > .header {
    display: flex;

    > .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    > .handle {
      flex: 1;
      cursor: grab;
    }
  }
  > .body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 8px 8px;

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: 280px;
      height: 90.5px;
      border-radius: 4px;
      background-color: rgba(white, 0.05);

      > .title {
        color: white;
        font-size: 14px;

        &.loading::after {
          content: '';
          width: 16px;
          display: inline-block;
          animation: dots 1s steps(4, end) infinite;
        }
      }
      > .desc {
        color: gray;
        font-size: 12px;
        text-align: center;
        line-height: 1.4;
      }
    }
  }
}

@keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
