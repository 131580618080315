@import '@/styles/common.scss';

.message {
  margin-top: 16px;
  font-size: 18px;
  color: white;
  text-align: center;
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  gap: 8px;

  > select {
    @include field;
    width: 240px;
    height: 32px;
    font-size: 13px;
    padding: 0 8px 0 4px;
  }
}
.bottom {
  margin-top: 24px;
  display: flex;
  justify-content: center;

  > .button {
    @include button;
    width: 104px;
    height: 40px;
    font-size: 14px;
  }
}
