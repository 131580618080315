@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 720px;
  height: 36px;
  border-radius: 4px;
  background-color: rgba(black, 0.9);
  cursor: pointer;

  > .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    padding: 0 16px;

    > .count {
      color: white;
      font-size: 12px;
    }
  }
  > .body {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 0 8px 0 16px;

    > .time {
      color: darkgray;
      font-size: 12px;
    }
    > .robot {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      > .color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
      > .name {
        color: white;
        font-size: 12px;
        width: 120px;
      }
    }
    > .text {
      flex: 1;
      color: white;
      font-size: 12px;
      white-space: nowrap;
    }
    > .close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
    }
  }
}
