@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  > .content {
    flex: 1;
    position: relative;
    overflow: hidden;

    > .leftTop {
      position: absolute;
      top: 0;
      left: 8px;
      display: flex;
      gap: 8px;
      z-index: 10;
      pointer-events: none;
    }
    > .centerTop {
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
    > .rightTop {
      position: absolute;
      top: 0;
      right: 8px;
      width: 320px;
      z-index: 10;
    }
    > .centerBottom {
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 20;
    }
  }
}
