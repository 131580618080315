@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -8px;
  padding: 16px;
  border-radius: 4px;
  background-color: rgba(#111111, 0.9);
  box-sizing: border-box;
  opacity: 0;
  transition: all 0.2s;

  &.show {
    opacity: 1;
    transform: translateY(-100%);
  }
  > .close {
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  > .wrapper {
    margin-top: 8px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px dashed $line-color;

    > .message {
      color: white;
      font-size: 14px;
      text-align: center;
    }
    > .robots {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;

      > .item {
        display: flex;
        flex-direction: row;
        border-radius: 16px;
        background-color: $button-color;

        > .thumbnail {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
        }
        > .name {
          display: flex;
          align-items: center;
          padding: 0 12px 0 8px;
          color: white;
          font-size: 12px;
          text-wrap: nowrap;
        }
      }
    }
  }
}
