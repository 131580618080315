@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 280px;

  > .form {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 8px;
    width: 200px;

    > label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: white;

      > .fieldWrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        > input {
          @include field;
          width: 48px;
          height: 28px;
          font-size: 14px;
          text-align: center;
        }
        > .unit {
          width: 0;
          font-size: 70%;
          margin-right: 16px;
        }
      }
    }
  }
  > .switch {
    align-self: center;
    position: relative;
    width: 240px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(white, 0.4);

    > .bar {
      position: absolute;
      margin: 2px;
      width: calc(50% - 4px);
      top: 0;
      left: 50%;
      bottom: 0;
      border-radius: 6px;
      background-color: $tint-color;
      transition: left 0.2s;

      &.left {
        left: 0;
      }
    }
    > .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      > .button {
        flex: 1;
        font-size: 12px;
        color: white;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  > .message {
    color: white;
    font-size: 13px;
    line-height: 1.6;
    text-align: center;
  }
  > .disarm {
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    animation: blink 0.5s ease-in-out infinite;
  }
  > .option {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: white;
    font-size: 12px;
    line-height: 1.6;
  }
}

@keyframes blink {
  50% {
    color: red;
  }
}
