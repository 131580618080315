@import '@/styles/common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  cursor: pointer;
  transition: all 0.2s;

  &.up {
    transform: rotate(180deg);
  }
}
