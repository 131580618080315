@import '@/styles/common.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 12px 0;
  border-radius: 4px;
  background-color: rgba(white, 0.05);
  position: relative;

  &.clickable {
    cursor: pointer;
  }
  > .label {
    font-size: 10px;
    color: darkgray;
  }
  > .value {
    display: flex;
    align-items: flex-end;
    gap: 2px;
    font-size: 14px;
    font-weight: 500;
    color: white;

    > .unit {
      font-size: 70%;
    }
  }
  > .pagination {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 4px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    > .dot {
      width: 8px;
      height: 2px;
      background-color: $line-color;
      transition: all 0.2s;

      &.active {
        height: 3px;
        background-color: $tint-color;
      }
    }
  }
}
