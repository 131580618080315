@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  > .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    > .title {
      color: white;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
    > .message {
      color: white;
      font-size: 13px;
      font-weight: lighter;
      text-align: center;
    }
  }
  > .joysticks {
    display: flex;
    gap: 16px;
    pointer-events: none;

    > .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      > .info {
        display: flex;
        gap: 8px;

        > .item {
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 11px;
          width: 36px;
          height: 20px;
          padding-bottom: 2px;
          box-sizing: border-box;
          border-radius: 4px;
          background-color: $field-color;
        }
      }
    }
  }
}
