@use 'sass:color';

// Colors
$tint-color: #41a3ff;
$line-color: #777777;
$modal-color: rgba(black, 0.7);
$field-color: #333333;
$button-color: #444444;
$sidebar-color: #333333;
$topbar-color: #222222;
$bottombar-color: #222222;
$button-pressed-color: darken($tint-color, 30%);

// prettier-ignore
@mixin text-shadow {
  text-shadow:
  -1px -1px 0 black,
     0 -1px 0 black,
   1px -1px 0 black,
   1px    0 0 black,
   1px  1px 0 black,
     0  1px 0 black,
  -1px  1px 0 black,
  -1px    0 0 black;
}

// Scrollable
@mixin scrollable {
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(black, 0.8);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(white, 0.2);
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $line-color;
  }
}

// Field
@mixin field {
  color: white;
  box-sizing: border-box;
  border-radius: 2px;
  border-color: $line-color;
  background-color: $field-color;
  outline: none;
  transition: border-color 0.2s;

  &:focus:not(:read-only) {
    border-color: $tint-color;
  }
}

// Button
@mixin button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: $button-color;
  box-shadow: 0 1px 2px 0 rgba(white, 0.2) inset;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: darken($button-color, 5%);
  }
  &.accent {
    background-color: $tint-color;

    &:hover {
      background-color: darken($tint-color, 5%);
    }
  }
  &.pressed {
    background-color: $button-pressed-color;
    box-shadow: 0 1px 2px 0 black inset;

    &:hover {
      background-color: darken($button-pressed-color, 5%);
    }
  }
}
