@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  z-index: 40;

  > .term {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 56px;
    gap: 4px;

    > .label {
      font-size: 10px;
      color: darkgray;
      white-space: nowrap;
    }
    > .value {
      font-size: 14px;
      font-weight: bold;
      color: white;
      white-space: nowrap;
    }
  }
}
