@import '@/styles/common.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  > .methods {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid $line-color;

    > .method {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      height: 160px;
      padding: 32px 32px 0;
      box-sizing: border-box;
      border-right: 1px dashed $line-color;
      cursor: pointer;

      &:last-child {
        border-right: none;
      }
      > .title {
        font-size: 14px;
        color: white;
      }
      > .desc {
        color: gray;
        font-size: 12px;
        text-align: center;
        line-height: 1.4;
      }
    }
  }
  > .form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: white;
      gap: 24px;

      > input {
        @include field;
        width: 560px;
        height: 32px;
        font-size: 14px;
        padding-left: 8px;
      }
    }
  }
  > .button {
    align-self: flex-end;
  }
}
