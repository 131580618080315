@import '@/styles/common.scss';

.container {
  display: none;
  background-color: rgba(#111111, 0.9);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;

  &.show {
    display: block;
  }
  > .chartWrapper {
    position: relative;

    > .canvas {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    > .handle {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-width: 4px;
      border-style: solid;
      border-color: $tint-color $tint-color transparent transparent;
      cursor: ew-resize;
    }
    > .buttons {
      position: absolute;
      display: flex;
      top: 8px;
      right: 14px;
      gap: 4px;

      > .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        border: 1px solid $line-color;
        background-color: $field-color;
        transition: background-color 0.2s;
        cursor: pointer;

        &:hover {
          background-color: darken($field-color, 5%);
        }
      }
    }
  }
}
