@import '@/styles/common.scss';

.container {
  transition: transform 0.2s;

  > .header {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);

    > .line {
      width: 1px;
      height: 20px;
      margin: 0 auto;
      background-color: white;
    }
    > .arrow {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
    }
  }
}
