@import '@/styles/common.scss';

.container {
  position: relative;
  background-color: rgba(black, 0.6);
  border-radius: 4px;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    height: 40px;
    gap: 8px;
    cursor: pointer;

    > .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      > .marker {
        transform: scale(0.65);
        transform-origin: left center;
      }
      > .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid $line-color;
        box-sizing: border-box;
        transform: scale(0.65);
        transform-origin: left center;
      }
      > .type {
        padding-right: 8px;
        color: white;
        font-size: 12px;
        border: none;
        outline: none;
        background-color: transparent;

        &:disabled {
          opacity: 1;
        }
        > option {
          background-color: $field-color;
        }
      }
    }
    > .right {
      display: flex;
      flex-direction: row;
      align-items: center;

      > .more {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 24px;
      }
    }
  }
  > .body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s;

    &.open {
      max-height: 800px;
    }
    > .inner {
      margin: 0 8px;
      border-top: 0.1px solid $line-color;
    }
  }
  > .panel {
    display: none;
    position: fixed;
    background-color: #222222;
    padding: 4px 0;
    border-radius: 4px;
    border: 1px solid $tint-color;
    z-index: 10;

    &.open {
      display: block;
    }
    > .arrow {
      position: absolute;
      top: -6px;
      left: 2px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 6px solid;
      border-bottom-color: $tint-color;
    }
    > .item {
      display: flex;
      align-items: center;
      height: 24px;
      padding: 0 12px;
      color: white;
      font-size: 11px;
      cursor: pointer;

      &:hover {
        background-color: darken(#222222, 5%);
      }
    }
  }
}
