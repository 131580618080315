@import '@/styles/common.scss';

.container {
  @include scrollable;
  border-radius: 4px;
  background-color: rgba(white, 0.05);
  height: 0;
  transition: all 0.2s;

  &.open {
    margin-top: 8px;
    height: 96px;
  }
  > .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  > .row {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 24px;
    padding-right: 8px;
    border-bottom: 0.1px dashed $line-color;

    > .textWrapper {
      flex: 1;
      padding-left: 8px;
      overflow: hidden;

      > .text {
        color: white;
        font-size: 11px;
        line-height: 1.4;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.marquee {
          width: min-content;
          animation: marquee 5s linear infinite;
        }
      }
    }
    > .time {
      color: darkgray;
      font-size: 11px;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-90%);
  }
}
