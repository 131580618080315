@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: fixed;
  border-radius: 4px;
  margin-top: 8px;
  right: 8px;
  padding: 12px 16px;
  background-color: $modal-color;

  > .message {
    color: white;
    font-size: 12px;
  }
  > input {
    @include field;
    width: 40px;
    height: 24px;
    font-size: 12px;
    text-align: center;
  }
  > .button {
    @include button;
    width: 56px;
    height: 24px;
    font-size: 11px;
  }
}
