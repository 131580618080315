@import '@/styles/common.scss';

.container {
  display: flex;
  align-items: center;
  height: 28px;

  > .button {
    @include button;
    height: 28px;
    padding: 0 8px;
    font-size: 12px;
  }
  > .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    > .spin {
      animation: rotating 1s linear infinite;
    }
    > .text {
      color: white;
      font-size: 12px;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
