@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-left: 20px;

  > .weather {
    display: flex;
    align-items: center;
    justify-content: center;

    > .icon {
      width: 44px;
      height: 44px;
    }
  }
  > .term {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 56px;
    gap: 4px;
    z-index: 40;

    &.pointer {
      cursor: pointer;
    }
    > .label {
      font-size: 10px;
      color: darkgray;
      white-space: nowrap;
    }
    > .value {
      font-size: 14px;
      font-weight: bold;
      color: white;
    }
    > .panel {
      display: none;
      position: absolute;
      bottom: calc(100% + 4px);
      left: -16px;
      padding: 12px;
      border-radius: 4px;
      background-color: rgba(black, 0.9);
      overflow: hidden;
      cursor: default;

      &.show {
        display: block;
      }
      > .title {
        font-size: 12px;
        color: white;
      }
      > .wrapper {
        margin-top: 8px;
        width: 200px;
        height: 200px;
        position: relative;

        > .step {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          color: white;
          font-size: 11px;
          text-align: center;

          > .name {
            font-weight: 500;
          }
          > .value {
            transform: scale(0.8);
          }
        }
        > .line {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          &:after {
            content: '';
            position: absolute;
            top: 28px;
            right: 0;
            bottom: 28px;
            left: calc(50% - 0.25px);
            border-left: 0.5px dashed $line-color;
          }
        }
        > .arrow {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transition: transform 0.2s;

          &:after {
            content: '';
            position: absolute;
            top: 28px;
            right: 0;
            bottom: 28px;
            left: calc(50% - 1px);
            border-left: 2px solid $tint-color;
          }
          > .head {
            position: absolute;
            top: 24px;
            left: calc(50% - 4px);
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 8px solid $tint-color;
          }
        }
        > .label {
          display: flex;
          align-items: center;
          width: fit-content;
          font-size: 10px;
          color: white;
          padding: 0 4px;
          border-radius: 2px;
          height: 16px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: $tint-color;
        }
      }
    }
  }
}
