@import '@/styles/common.scss';

.container {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .attitude {
    margin-top: 12px;
  }
  .pane {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 8px 0;

    > .item {
      display: flex;
      flex-direction: column;
      gap: 6px;

      > .header {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        > .title {
          font-size: 10px;
          font-weight: bold;
          color: white;
        }
        > .unit {
          font-size: 8px;
          color: white;
        }
      }
      > table {
        border-top: 0.5px solid $line-color;

        > thead {
          background-color: rgba(white, 0.05);
        }
        tr {
          height: 18px;

          > td {
            font-size: 9px;
            color: white;
            text-align: center;
            border-bottom: 0.5px solid $line-color;
          }
        }
      }
      > .chartWrapper {
        position: relative;
        width: 100%;
        height: 120px;
        background-color: rgba(white, 0.05);
      }
    }
  }
}
