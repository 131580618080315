@import '@/styles/common.scss';

.container {
  position: relative;
  background-color: rgba(black, 0.6);
  border-radius: 4px;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    height: 40px;
    gap: 8px;
    cursor: pointer;

    > .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      > .marker {
        transform: scale(0.65);
        transform-origin: left center;
      }
      > .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid $line-color;
        box-sizing: border-box;
        transform: scale(0.65);
        transform-origin: left center;
      }
      > .title {
        color: white;
        font-size: 12px;
      }
    }
    > .right {
      display: flex;
      flex-direction: row;
      align-items: center;

      > .current {
        display: flex;
        align-items: center;
        height: 16px;
        padding: 0 4px;
        color: white;
        font-size: 10px;
        border-radius: 2px;
        background-color: $tint-color;
        animation: blink 1s ease-in-out infinite;
      }
    }
  }
  > .body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s;

    &.open {
      max-height: 800px;
    }
    > .inner {
      margin: 0 8px;
      border-top: 0.1px solid $line-color;
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0.5;
  }
}
