@import '@/styles/common.scss';

.container {
  margin: 12px 8px 0;
  position: relative;
  border-radius: 4px;
  background-color: rgba(white, 0.05);

  > .type {
    display: flex;
    align-items: center;
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 0 8px;
    height: 16px;
    font-size: 10px;
    color: white;
    border: 1px solid $line-color;
    border-radius: 2px;
    background-color: rgba(black, 0.1);
  }
}
