@import '@/styles/common.scss';

.fields {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: white;
    gap: 24px;

    > input {
      @include field;
      width: 160px;
      height: 32px;
      font-size: 14px;
      padding-left: 8px;
    }
  }
}
.bottom {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  > .button {
    @include button;
    width: 104px;
    height: 40px;
    font-size: 14px;
  }
}
