@import '@/styles/common.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div {
    > select {
      @include field;
      height: 32px;
      font-size: 13px;
      padding: 0 8px 0 4px;
    }
    > .table {
      margin-top: 8px;
      width: 600px;
      padding: 16px;
      background-color: rgba(black, 0.5);
      border-radius: 4px;
      position: relative;

      > .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 28px;
        padding-right: 8px;
        border-bottom: 0.5px solid $line-color;

        > .column {
          font-size: 12px;
          color: white;

          &.no {
            width: 56px;
            text-align: center;
          }
          &.question {
            flex: 1;
            text-align: center;
          }
          &.check {
            width: 96px;
            text-align: center;
          }
        }
      }
      > .body {
        @include scrollable;
        overflow-y: scroll;
        height: 400px;

        > .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 40px;
          border-bottom: 0.5px solid $line-color;

          > .column {
            font-size: 12px;
            color: white;

            &.no {
              width: 56px;
              text-align: center;
            }
            &.question {
              flex: 1;
              max-width: 560px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            &.check {
              width: 96px;
              text-align: center;

              > svg {
                cursor: pointer;
              }
            }
          }
        }
        > .empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
          height: 100%;

          > .title {
            color: white;
            font-size: 14px;
          }
          > .message {
            color: gray;
            font-size: 12px;
            text-align: center;
            line-height: 1.4;
          }
          > .button {
            @include button;
            margin-top: 8px;
            height: 28px;
            padding: 0 8px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.bottom {
  margin-top: 24px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  > .button {
    @include button;
    width: 104px;
    height: 40px;
    font-size: 14px;
  }
}
