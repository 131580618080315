@import '@/styles/common.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: row;

  > .menus {
    width: 160px;
    padding: 12px 24px;
    box-sizing: border-box;

    > .menu {
      display: flex;
      align-items: center;
      height: 32px;
      font-size: 12px;
      color: white;
      transition: color 0.2s;
      cursor: pointer;

      &.target {
        color: $tint-color;
        font-weight: bold;
      }
    }
  }
  > .console {
    flex: 1;
    background-color: #222222;

    > .message {
      margin: 16px;
      color: white;
      font-size: 14px;
    }
  }
}
