@import '@/styles/common.scss';

.container {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 0.1px;
    height: 100%;
    background-color: $line-color;
    transform: translateY(-50%);
  }
}
