@import '@/styles/common.scss';

.container {
  width: 320px;

  > .filebar {
    display: flex;
    align-items: center;
    height: 48px;
    background: linear-gradient(180deg, #333333 0%, #111111 100%);

    > .button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      cursor: pointer;
    }
  }
  > .namebar {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px 0;
    background: linear-gradient(180deg, #333333 0%, #111111 100%);

    > .field {
      @include field;
      flex: 1;
      height: 32px;
      font-size: 14px;
      padding-left: 8px;
    }
    > .button {
      @include button;
      width: 32px;
      height: 32px;
      position: relative;

      > .as {
        position: absolute;
        right: 4px;
        bottom: 4px;
        color: white;
        font-size: 12px;
        text-shadow: -2px 0 $sidebar-color, 0 2px $sidebar-color, 2px 0 $sidebar-color, 0 -2px $sidebar-color;
      }
    }
  }
  > .summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 12px 12px 16px;
    background-color: rgba(black, 0.8);

    > .total {
      color: white;
      font-size: 14px;
    }
    > .values {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      > .item {
        color: white;
        font-size: 12px;

        > span {
          font-size: 10px;
        }
      }
      > .speedSettings {
        position: relative;
        margin-left: -8px;

        > .settings {
          cursor: pointer;
        }
        > .panel {
          display: none;
          align-items: center;
          position: absolute;
          top: 50%;
          left: 24px;
          height: 32px;
          padding: 0 12px;
          background: #222222;
          border-radius: 4px;
          border: 1px solid $tint-color;
          transform: translateY(-50%);

          &.show {
            display: flex;
          }
          > .arrow {
            position: absolute;
            left: -6px;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 6px solid;
            border-right-color: $tint-color;
          }
          > label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            color: white;
            font-size: 11px;
            text-wrap: nowrap;
            gap: 8px;

            > .fieldWrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;

              > input {
                @include field;
                width: 32px;
                height: 20px;
                font-size: 12px;
                text-align: right;
                padding-right: 4px;
              }
              > .unit {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
  > .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding-bottom: 12px;
    background-color: rgba(black, 0.8);

    > .button {
      @include button;
      height: 24px;
      padding: 0 8px;
      gap: 4px;

      > .label {
        font-size: 11px;
      }
    }
  }
  > .body {
    @include scrollable;
    overflow-y: scroll;
    max-height: calc(100vh - 624px);
    background-color: rgba(black, 0.6);

    > .empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
    }
    > .inner {
      padding: 8px 6px 8px 8px;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
  > .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
    background-color: rgba(black, 0.8);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    > .title {
      color: white;
      font-size: 14px;
    }
    > select {
      @include field;
      width: 160px;
      height: 32px;
      font-size: 13px;
      padding: 0 8px 0 4px;
    }
  }
}
