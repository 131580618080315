@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  transition: color 0.2s;

  > .label {
    color: white;
    font-size: 11px;
  }
  > .value {
    display: flex;
    align-items: flex-end;
    color: white;
    font-size: 12px;
    font-weight: 500;
    gap: 2px;

    > .unit {
      width: 0;
      font-size: 70%;
    }
  }
}
