@import '@/styles/common.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  > .message {
    color: white;
    font-size: 16px;
    line-height: 1.6;
  }
  > .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: center;

    > .button {
      @include button;
      width: 104px;
      height: 40px;
      font-size: 14px;
    }
  }
}
