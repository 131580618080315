@import '@/styles/common.scss';

.container {
  display: flex;
  align-items: center;
  color: $tint-color;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  height: 16px;

  > .waiting {
    color: gray;

    > .dots::after {
      content: '';
      animation: dots 1s steps(4, end) infinite;
    }
  }
}

@keyframes dots {
  0%,
  20% {
    content: '';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%,
  100% {
    content: '...';
  }
}
