@import '@/styles/common.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    > .caption {
      font-size: 14px;
      color: white;
    }
    > .timestamp {
      font-size: 11px;
      color: white;
    }
  }
  > canvas {
    background-color: rgba(white, 0.05);
  }
}
