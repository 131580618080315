@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 12px;

  > .app {
    color: white;
    font-size: 10px;
  }
  > .title {
    color: white;
    font-size: 14px;
    font-weight: 500;
  }
}
