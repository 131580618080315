@import '@/styles/common.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 28px;
    padding-right: 8px;
    border-bottom: 0.5px solid $line-color;

    > .column {
      font-size: 12px;
      color: white;

      &.no {
        width: 56px;
        text-align: center;
      }
      &.question {
        flex: 1;
        text-align: center;
      }
      &.delete {
        width: 96px;
        text-align: center;
      }
    }
  }
  > .body {
    @include scrollable;
    flex: 1;
    overflow-y: scroll;

    > .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      border-bottom: 0.5px solid $line-color;

      > .column {
        font-size: 12px;
        color: white;

        &.no {
          width: 56px;
          text-align: center;
        }
        &.question {
          flex: 1;
          max-width: 560px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &.delete {
          width: 96px;
          display: flex;
          justify-content: center;

          > .button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            box-sizing: border-box;
            border-radius: 2px;
            border: 1px solid $line-color;
            background-color: $field-color;
            transition: background-color 0.2s;
            cursor: pointer;

            &:hover {
              background-color: darken($field-color, 5%);
            }
          }
        }
      }
    }
    > .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  > .form {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    > input {
      @include field;
      width: 100%;
      height: 28px;
      font-size: 13px;
      padding-left: 8px;
    }
    > .button {
      @include button;
      height: 28px;
      padding: 0 8px;
      font-size: 12px;
    }
  }
}
