@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .groups {
    display: flex;
    gap: 8px;
    width: max-content;

    > .group {
      padding: 8px;
      border-radius: 4px;
      background-color: rgba(#111111, 0.9);

      > .name {
        color: white;
        font-size: 13px;
        font-weight: 600;
      }
      > .columns {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        gap: 8px;

        > .column {
          display: flex;
          flex-direction: column;
          gap: 8px;

          > .blank {
            width: 104px;
            height: 32px;
            border-radius: 2px;
            background-color: rgba($button-color, 0.4);
            box-shadow: 0 1px 2px 0 rgba(black, 0.4) inset;
          }
          > .button {
            @include button;
            justify-content: flex-start;
            gap: 4px;
            width: 104px;
            height: 32px;
            padding-left: 8px;

            > .label {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
