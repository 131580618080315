@import '@/styles/common.scss';

.container {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > .rows {
    padding: 0 12px 0 8px;
  }
  > .boxes {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    gap: 8px;

    > .box {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      padding: 12px 0;
      border-radius: 4px;
      background-color: rgba(white, 0.05);

      &.battery {
        gap: 8px;

        > .items {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;

          > .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            > .battery {
              position: relative;
              width: 64px;
              height: 32px;
              padding: 2px;
              border: 1px solid $line-color;
              border-radius: 4px;
              box-sizing: border-box;

              > .bar {
                height: 100%;
                border-radius: 2px;
                background-color: green;
              }
              > .value {
                position: absolute;
                top: 50%;
                left: 50%;
                display: flex;
                align-items: flex-end;
                gap: 1px;
                font-size: 12px;
                font-weight: 500;
                color: white;
                transform: translate(-50%, -50%);

                > .unit {
                  font-size: 70%;
                }
              }
            }
            > .info {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 2px;
              width: 32px;

              > .value {
                display: flex;
                align-items: flex-end;
                gap: 1px;
                font-size: 11px;
                font-weight: 500;
                color: white;

                > .unit {
                  font-size: 70%;
                }
              }
            }
          }
        }
      }
      &.motor {
        gap: 8px;

        > .items {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          > .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2px;

            > .rotate {
              animation-name: rotating;
              animation-timing-function: linear;
              animation-iteration-count: infinite;
            }
            > .value {
              display: flex;
              align-items: flex-end;
              gap: 1px;
              font-size: 12px;
              font-weight: 500;
              color: white;

              > .unit {
                font-size: 70%;
              }
            }
          }
        }
      }
      > .label {
        font-size: 10px;
        color: darkgray;
      }
      > .value {
        display: flex;
        align-items: flex-end;
        gap: 2px;
        font-size: 14px;
        font-weight: 500;
        color: white;

        > .unit {
          font-size: 70%;
        }
      }
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
