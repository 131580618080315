@import '@/styles/common.scss';

.container {
  @include scrollable;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow-y: scroll;

  > .message {
    color: white;
    font-size: 14px;
  }
  > .submessage {
    color: white;
    font-size: 12px;
  }
  > .button {
    @include button;
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
  }
  > .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    > .spin {
      animation: rotating 1s linear infinite;
    }
    > .text {
      color: white;
      font-size: 12px;
    }
  }
  > .progress {
    position: relative;
    width: 100%;
    border-radius: 2px;
    border: 1px solid $line-color;
    padding: 1px;
    display: none;

    &.show {
      display: block;
    }
    > .bar {
      height: 20px;
      border-radius: 1px;
      background-color: $tint-color;
    }
    > .text {
      position: absolute;
      top: 50%;
      left: 50%;
      color: white;
      font-size: 12px;
      transform: translate(-50%, -50%);
    }
  }
  > img {
    width: 400px;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
