@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .name {
    background-color: rgba(black, 0.5);
    font-size: 11px;
    color: white;
    border-radius: 2px;
    padding: 4px 6px 6px;
    margin-bottom: 4px;
  }
  > .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    > .label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: rgba(black, 0.3);
      color: white;
      font-size: 12px;
      font-weight: bold;
      z-index: 0;
    }
  }
  > .arrow {
    margin-top: -5px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 16px solid;
  }
}
