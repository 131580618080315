@import '@/styles/common.scss';

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/images/bg-login.png');
  background-position: center bottom;
  background-size: cover;

  > .form {
    padding: 96px 120px;
    border-radius: 8px;
    background-color: rgba(black, 0.5);

    > .formInner {
      display: flex;
      flex-direction: column;
      width: 400px;
      gap: 8px;

      > .loader {
        display: flex;
        align-items: center;
        align-self: center;
        gap: 8px;

        > .message {
          color: white;
          font-size: 16px;
        }
      }
      > .item {
        flex: 1;
        border-radius: 2px;
        border: 1px solid $line-color;
        background-color: $field-color;
        padding: 8px;

        > select {
          @include field;
          width: 100%;
          height: 32px;
          font-size: 13px;
          padding: 0 8px 0 4px;
        }
        > table {
          margin-top: 8px;
          margin-left: 24px;
          border-spacing: 6px;

          tr {
            color: white;
            font-size: 12px;
            font-family: 'Noto Sans Mono', monospace;

            > th {
              text-transform: capitalize;
              text-align: right;
            }
            > td {
              padding-left: 8px;
            }
          }
        }
      }
      > .button {
        @include button;
        margin-top: 16px;
        height: 76px;
        font-size: 20px;
        font-weight: bold;
        width: 100%;

        &.cancel {
          margin-top: 8px;
        }
      }
    }
  }
}
