@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;

  > .tabs {
    display: flex;
    flex-direction: row;
    border-bottom: 0.5px solid $line-color;
    gap: 16px;

    > .tab {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: white;
      padding: 8px;
      margin-bottom: -0.5px;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active {
        color: $tint-color;
        border-bottom: 2px solid $tint-color;
      }
    }
  }
  > .pane {
    display: none;
    width: 720px;
    height: 480px;
    background-color: rgba(black, 0.5);

    &.active {
      display: flex;
    }
  }
}
.bottom {
  margin-top: 24px;
  display: flex;
  justify-content: center;

  > .button {
    @include button;
    width: 104px;
    height: 40px;
    font-size: 14px;
  }
}
