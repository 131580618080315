@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  > .message {
    color: white;
    font-size: 13px;
    line-height: 1.6;
    text-align: center;
  }
  > select {
    @include field;
    width: 240px;
    height: 32px;
    font-size: 13px;
    padding: 0 8px 0 4px;
  }
  > .agree {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: white;
    font-size: 12px;
  }
}
