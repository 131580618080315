@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0 12px 4px;

  > label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-size: 12px;

    > .fieldWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      > input {
        @include field;
        width: 96px;
        height: 20px;
        font-size: 12px;
        text-align: right;
        padding-right: 4px;
      }
      > .unit {
        font-size: 11px;
        width: 24px;
      }
    }
  }
  > .message {
    padding: 8px 4px 8px 0;
    color: darkgray;
    font-size: 12px;
    text-align: center;
  }
}
