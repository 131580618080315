@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  position: relative;

  > .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    > .name {
      color: white;
      font-size: 14px;
      width: 144px;
    }
  }
  > .panel {
    display: none;
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    right: 0;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: rgba(black, 0.9);
    z-index: 20;

    &.show {
      display: block;
    }
    > .menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 0;
      gap: 4px;
      cursor: pointer;

      > .name {
        font-size: 12px;
        color: white;
        white-space: nowrap;

        &.active {
          color: $tint-color;
        }
      }
    }
  }
}
