@import '@/styles/common.scss';

.container {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > .rows {
    padding: 0 12px 0 8px;
  }
  > .boxes {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    gap: 8px;
  }
}
