@import '@/styles/common.scss';

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/images/bg-login.png');
  background-position: center bottom;
  background-size: cover;

  > .form {
    padding: 96px 120px;
    border-radius: 8px;
    background-color: rgba(black, 0.5);

    > .formInner {
      display: flex;
      flex-direction: column;
      width: 400px;
      gap: 8px;

      &.hidden {
        display: none;
      }
      > .field {
        @include field;
        width: 100%;
        height: 56px;
        font-size: 16px;
        padding-left: 16px;
      }
      > .option {
        margin-top: 16px;
        display: flex;
        align-items: center;
        width: fit-content;
        gap: 8px;
        font-size: 16px;
        color: white;
      }
      > .timestamp {
        color: white;
        font-size: 18px;
        text-align: center;
        line-height: 1.8;

        > small {
          font-size: 14px;
        }
        > .accent {
          color: $tint-color;
        }
      }
      > .button {
        @include button;
        margin-top: 32px;
        height: 76px;
        font-size: 20px;
        font-weight: bold;
        width: 100%;

        &.cancel {
          margin-top: 8px;
        }
      }
    }
  }
}
