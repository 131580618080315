@import '@/styles/common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid $line-color;
  border-radius: 4px;
  background-color: black;
  transform: translate(-50%, -50%);
}
