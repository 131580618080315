@import '@/styles/common.scss';

.container {
  position: fixed;
  display: none;

  &.visible {
    display: block;
  }
  > .line {
    position: absolute;
    width: 22.62px;
    height: 1px;
    background-color: black;
    transform-origin: top left;
    transform: rotate(45deg);
  }
  > .text {
    @include text-shadow;
    margin-top: 16px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 500;
    color: white;
    text-wrap: nowrap;
  }
}
