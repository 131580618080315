@import '@/styles/common.scss';

.container {
  position: relative;
  height: 184px;
  background-color: rgba(black, 0.9);

  > .canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
