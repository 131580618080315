@import '@/styles/common.scss';

.container {
  @include scrollable;
  max-height: calc(100vh - 112px);
  scrollbar-width: none;
  padding: 8px 0;
  box-sizing: border-box;

  > .wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;

    > .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 320px;
      height: 48px;
      padding: 0 12px;
      background-color: rgba(black, 0.8);
      border-radius: 4px;
      box-sizing: border-box;
      pointer-events: auto;

      > .total {
        color: white;
        font-size: 14px;
      }
      > .option {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        color: white;
        font-size: 12px;
      }
    }
  }
}
