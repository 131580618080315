@import '@/styles/common.scss';

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/images/bg-login.png');
  background-position: center bottom;
  background-size: cover;

  > .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 480px;
    padding: 96px 120px;
    border-radius: 8px;
    background-color: rgba(black, 0.5);

    > .message {
      color: white;
      font-size: 18px;
      text-align: center;
      line-height: 1.8;

      > .accent {
        color: $tint-color;
      }
    }
    > .button {
      @include button;
      margin-top: 32px;
      height: 76px;
      font-size: 20px;
      font-weight: bold;
      width: 100%;
      max-width: 400px;
    }
  }
}
