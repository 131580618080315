@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 280px;

  > .form {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 8px;
    width: 200px;

    > label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: white;

      > .fieldWrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        > input {
          @include field;
          width: 48px;
          height: 28px;
          font-size: 14px;
          text-align: center;
        }
        > .unit {
          width: 0;
          font-size: 70%;
          margin-right: 16px;
        }
      }
    }
  }
  > .message {
    color: white;
    font-size: 13px;
    line-height: 1.6;
    text-align: center;
  }
  > .disarm {
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    animation: blink 0.5s ease-in-out infinite;
  }
  > .option {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: white;
    font-size: 12px;
    line-height: 1.6;
  }
}

@keyframes blink {
  50% {
    color: red;
  }
}
