@import '@/styles/common.scss';

.container {
  > label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-size: 12px;

    > .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      > .unit {
        font-size: 11px;
        width: 24px;
      }
    }
  }
}
