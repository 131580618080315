@import '@/styles/common.scss';

.wrapper {
  display: flex;
  gap: 8px;

  > div {
    > select {
      @include field;
      height: 32px;
      font-size: 13px;
      padding: 0 8px 0 4px;
    }
    > .table {
      margin-top: 16px;
      width: 560px;
      padding: 16px;
      background-color: rgba(black, 0.5);
      border-radius: 4px;
      position: relative;

      > .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 28px;
        padding-right: 8px;
        border-bottom: 0.5px solid $line-color;

        > .column {
          font-size: 12px;
          color: white;

          &.no {
            width: 48px;
            text-align: center;
          }
          &.name {
            flex: 1;
          }
          &.time {
            width: 128px;
            text-align: center;
          }
          &.duration {
            width: 72px;
            text-align: center;
          }
        }
      }
      > .body {
        @include scrollable;
        overflow-y: scroll;
        height: 320px;

        > .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 40px;
          border-bottom: 0.5px solid $line-color;
          transition: background-color 0.2s;
          cursor: pointer;

          &.target {
            background-color: rgba($tint-color, 0.3);
          }
          > .column {
            font-size: 12px;
            color: white;

            &.no {
              width: 48px;
              text-align: center;
            }
            &.name {
              flex: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 1.4;
            }
            &.time {
              width: 128px;
              text-align: center;
            }
            &.duration {
              width: 72px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  > .mapWrapper {
    width: 280px;
    background-color: rgba(black, 0.5);
    border-radius: 4px;
    position: relative;
    overflow: hidden;

    > .map {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      visibility: hidden;

      &.show {
        visibility: visible;
      }
    }
    > .info {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 4px;
      bottom: 4px;
      padding: 8px;
      border-radius: 2px;
      background-color: $modal-color;
      gap: 6px;

      > .item {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        > .label {
          font-size: 9px;
          color: white;
        }
        > .value {
          font-size: 9px;
          color: white;

          > span {
            font-size: 7px;
          }
        }
      }
    }
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.bottom {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > .button {
    @include button;
    width: 104px;
    height: 40px;
    font-size: 14px;
  }
}
