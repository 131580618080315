@import '@/styles/common.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-left: 20px;

  > .term {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    height: 56px;
    z-index: 40;
    cursor: pointer;

    > .label {
      font-size: 10px;
      color: darkgray;
    }
    > .value {
      font-size: 14px;
      font-weight: bold;
      color: white;
    }
    > .panel {
      display: none;
      position: absolute;
      bottom: calc(100% + 4px);
      left: 0;
      padding: 8px;
      border-radius: 4px;
      background-color: rgba(black, 0.9);
      gap: 8px;
      cursor: default;

      &.show {
        display: flex;
      }
      > .field {
        @include field;
        width: 200px;
        height: 32px;
        font-size: 14px;
        padding-left: 8px;
      }
    }
  }
}
