@import '@/styles/common.scss';

.container {
  padding-top: 8px;
  border-top: 0.5px solid $line-color;

  > .wrapper {
    display: flex;
    justify-content: space-between;

    > .side {
      display: flex;
      align-items: center;
      gap: 8px;

      > .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        color: white;
        cursor: pointer;

        &.active {
          color: $tint-color;
        }
        > .value {
          font-size: 11px;
        }
      }
    }
  }
}
