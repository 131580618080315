@import '@/styles/common.scss';

.container {
  > .tabs {
    display: flex;
    flex-direction: row;
    border-bottom: 0.5px solid $line-color;
    gap: 4px;
    padding-left: 4px;

    > .tab {
      display: flex;
      align-items: center;
      font-size: 11px;
      color: white;
      padding: 8px;
      margin-bottom: -0.5px;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active {
        color: $tint-color;
        border-bottom: 2px solid $tint-color;
      }
    }
  }
  > .pane {
    display: none;

    &.active {
      display: block;
    }
  }
}
