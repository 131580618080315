@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  position: relative;

  > .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    > .name {
      color: white;
      font-size: 14px;
      width: 144px;
    }
  }
  > .panel {
    display: none;
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    right: 0;
    padding: 0 12px;
    border-radius: 4px;
    background-color: rgba(black, 0.9);
    z-index: 20;

    &.show {
      display: block;
    }
    > .group {
      padding: 12px 0;

      &:not(:first-child) {
        border-top: 0.1px solid $line-color;
      }
      > .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        > .name {
          font-size: 14px;
          font-weight: bold;
          color: white;
        }
        > .label {
          display: flex;
          align-items: center;
          font-size: 9px;
          color: white;
          background-color: $line-color;
          height: 13px;
          padding: 0 4px;
          border-radius: 4px;
        }
      }
      > .items {
        margin-top: 8px;

        > .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px 0;
          gap: 4px;
          cursor: pointer;

          > .menuText {
            font-size: 12px;
            color: white;
            white-space: nowrap;

            &.active {
              color: $tint-color;
            }
          }
        }
      }
    }
  }
}
