@import '@/styles/common.scss';

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .marker {
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: bounce 1s infinite;

    > .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: $tint-color;

      > .label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: rgba(black, 0.3);
        z-index: 0;
      }
    }
    > .arrow {
      margin-top: -6px;
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top: 18px solid;
      border-top-color: $tint-color;
    }
  }
  > .plate {
    margin-top: -4px;
    width: 20px;
    height: 8px;
    border-radius: 50%;
    background-color: darken($tint-color, 30%);
  }
}
.message {
  margin-top: 16px;
  font-size: 18px;
  color: white;
  text-align: center;
}
.bottom {
  margin-top: 24px;
  display: flex;
  justify-content: center;

  > .button {
    @include button;
    width: 104px;
    height: 40px;
    font-size: 14px;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-4px);
  }
  40% {
    transform: translateY(0);
  }
}
