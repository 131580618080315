@import '../../styles/common.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  margin: -24px -32px 0;

  > .menus {
    width: 184px;
    padding: 12px 24px;
    box-sizing: border-box;

    > .menuGroup {
      > .menu {
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 12px;
        color: white;
        transition: color 0.2s;
        cursor: pointer;

        &.target {
          color: $tint-color;
          font-weight: bold;
        }
      }
      > .submenus {
        margin: -4px 0 6px 12px;

        > .submenu {
          display: flex;
          align-items: center;
          height: 24px;
          font-size: 11px;
          color: white;
          transition: color 0.2s;
          cursor: pointer;

          &.target {
            color: $tint-color;
            font-weight: bold;
          }
        }
      }
    }
  }
  > .content {
    flex: 1;
    position: relative;
    width: 800px;
    height: calc(800px * 3240px / 3840px);

    > .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;

      &.target {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
    > .desc {
      position: absolute;
      left: 16px;
      bottom: 16px;
      right: 16px;
      padding: 8px 12px;
      color: white;
      font-size: 12px;
      line-height: 1.4;
      border-radius: 4px;
      background-color: rgba(black, 0.5);
    }
    > .pagination {
      position: absolute;
      bottom: 24px;
      right: 24px;
      display: flex;
      border-radius: 4px;
      overflow: hidden;

      > button {
        width: 28px;
        height: 28px;
        background-color: rgba(black, 0.8);
      }
      > .middle {
        display: flex;
        align-items: center;
        gap: 12px;
        height: 28px;
        background-color: rgba(black, 0.8);

        > .pages {
          color: white;
          font-size: 12px;
          margin-bottom: 2px;

          > .current {
            color: $tint-color;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.bottom {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  position: relative;

  > .button {
    @include button;
    width: 104px;
    height: 40px;
    font-size: 14px;
  }
  > .option {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
    color: white;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
