@import '@/styles/common.scss';

.container {
  display: flex;
  justify-content: center;

  > .gauges {
    display: flex;
    gap: 12px;
    padding: 8px;
    border-radius: 60px;
    background-color: rgba(white, 0.05);

    > .gauge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 104px;
      height: 104px;
      border: 2px solid $line-color;
      border-radius: 50%;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;

      > * {
        position: absolute;
      }
      > .shadow {
        width: 100px;
        height: 100px;
      }
      &.left {
        > .background {
          width: 300px;
          height: 300px;
          transition: all 0.2s;
        }
        > .roll {
          width: 2px;
          height: 6px;
          background-color: red;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        > .coord {
          width: 104px;
          height: 104px;
          transition: all 0.2s;
        }
      }
      &.right {
        background: radial-gradient(50% 50% at 50% 50%, black 0%, #444444 100%);

        > .tics {
          width: 100%;
          height: 100%;

          > .tic {
            width: 100%;
            height: 100%;
            position: absolute;

            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
            }
            &.big:after {
              width: 2px;
              height: 6px;
              background-color: white;
            }
            &.medium:after {
              width: 1px;
              height: 5px;
              background-color: white;
            }
            &.small:after {
              width: 1px;
              height: 4px;
              background-color: $line-color;
            }
          }
          > .label {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            font-size: 10px;
            color: white;

            &.n {
              top: 6px;
              left: 50%;
              transform: scale(0.9) translateX(-50%);
            }
            &.e {
              right: 6px;
              top: 50%;
              transform: scale(0.9) translateY(-50%);
            }
            &.s {
              bottom: 6px;
              left: 50%;
              transform: scale(0.9) translateX(-50%);
            }
            &.w {
              left: 6px;
              top: 50%;
              transform: scale(0.9) translateY(-50%);
            }
          }
        }
        > .arrowWrapper {
          > .arrow {
            width: calc(44px * 0.8);
            height: calc(50px * 0.8);
          }
        }
        > .value {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          border: 1px solid white;
          background-color: rgba(black, 0.2);
          width: 32px;
          height: 16px;
          font-size: 10px;
          color: white;
          opacity: 0.8;
          transform: scale(0.9);
        }
      }
    }
  }
}
