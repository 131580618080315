@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  > .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    > .title {
      color: white;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
    > .message {
      color: white;
      font-size: 13px;
      font-weight: lighter;
      text-align: center;
    }
  }
  > .rows {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    > .row {
      display: flex;
      flex-direction: row;
      align-items: center;

      > .label {
        width: 96px;
        font-size: 12px;
        color: white;
      }
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.aiMode {
      gap: 12px;

      > .button {
        @include button;
        width: 104px;
        height: 40px;
        font-size: 14px;
      }
    }
    &.settings {
      gap: 8px;

      > .button {
        @include button;
        width: 52px;
        height: 28px;
        font-size: 12px;
      }
    }
  }
}
