@import '@/styles/common.scss';

.wrapper {
  margin-top: -8px;
  margin-left: -16px;
  margin-right: -16px;
  width: 264px;
}
.bottom {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  > .button {
    @include button;
    width: 104px;
    height: 40px;
    font-size: 14px;
  }
}
