@import '@/styles/common.scss';

.container {
  position: relative;

  > .slider {
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }
  > .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    color: white;
    font-size: 14px;
    transform: translate(-50%, -50%);

    > .spin {
      animation: rotating 1s linear infinite;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
