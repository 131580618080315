@import './common.scss';

* {
  padding: 0;
  margin: 0;
  user-select: none;
  line-height: 1;
}
body {
  overflow: hidden;
  font-family: 'Noto Sans KR', sans-serif;
}
a {
  text-decoration: none;
}
img {
  pointer-events: none;
}
button {
  appearance: none;
  border: 0;
  outline: 0;
  cursor: pointer;
}
input {
  border: 1px solid $line-color;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input,
textarea,
select,
button {
  font-family: inherit;
}
.gm-style iframe + div {
  border: none !important;
}
