@import '@/styles/common.scss';

.container {
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  > .viewFinder {
    border: none;
    vertical-align: bottom;
  }
  > .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#111111, 0.5);
    opacity: 0;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
    > .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
}
