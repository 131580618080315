@import '@/styles/common.scss';

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 48px 16px;
  background-color: rgba(black, 0.5);

  > .wrapper {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;

    > .content {
      > .title {
        font-size: 20px;
        font-weight: bold;
        color: white;
      }
      > p {
        margin-top: 16px;
        color: white;
        font-size: 12px;
        line-height: 1.6;
      }
    }
    > .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      > .button {
        @include button;
        width: 112px;
        height: 48px;
        font-size: 14px;
      }
    }
  }
}
