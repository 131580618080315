@import '@/styles/common.scss';

$size: 20px;
$thick: 2px;
$color: black;

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > .line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color;

    &.vertical {
      width: $thick;
      height: $size;
    }
    &.horizontal {
      width: $size;
      height: $thick;
    }
  }
}
