@import '@/styles/common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $line-color;
  overflow: hidden;
  cursor: pointer;
}
