@import '@/styles/common.scss';

.container {
  padding: 0 12px;
  background: linear-gradient(180deg, #111111 0%, rgba(black, 0.8) 100%);

  > .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 12px 4px;
    border-bottom: 0.1px dashed $line-color;

    > .term {
      display: flex;
      flex-direction: column;
      gap: 4px;

      > .label {
        font-size: 10px;
        color: darkgray;
      }
      > .value {
        font-size: 12px;
        font-weight: bold;
        color: white;
      }
    }
  }
}
