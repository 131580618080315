@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0 12px 4px;

  input {
    @include field;
    width: 96px;
    height: 20px;
    font-size: 12px;
    text-align: right;
    padding-right: 4px;

    &:read-only {
      color: lightgray;
    }
    &.danger {
      color: red;
    }
  }
  > .segments {
    @include scrollable;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    gap: 3px;
    max-height: 200px;

    > .row {
      margin-left: 8px;

      > label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: white;
        font-size: 11px;
        gap: 12px;

        > .gauge {
          flex: 1;
          position: relative;
          height: 4px;
          border: 1px solid $line-color;
          border-radius: 2px;

          > .bar {
            position: absolute;
            top: 0;
            bottom: 0;
            background-color: rgba($line-color, 0.8);

            &.active {
              background-color: $tint-color;
            }
          }
        }
        > .wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          > .radio {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12px;
            height: 12px;
            border: 1px solid $line-color;
            border-radius: 50%;
            cursor: pointer;
          }
          > .value {
            @include field;
            width: 64px;
            height: 16px;
            font-size: 11px;
            text-align: right;
            padding-right: 4px;
            color: lightgray;
          }
          > .unit {
            font-size: 10px;
            width: 24px;
          }
        }
      }
    }
  }
  select {
    @include field;
    height: 20px;
    font-size: 12px;
  }
  > .unit {
    font-size: 11px;
    width: 24px;
  }
  > .message {
    padding: 8px 4px 8px 0;
    color: darkgray;
    font-size: 12px;
    text-align: center;
  }
}
