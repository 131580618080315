@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  > .content {
    flex: 1;
    position: relative;
    overflow: hidden;

    > .leftTop {
      display: flex;
      position: absolute;
      top: 0;
      left: 8px;
    }
    > .leftBottom {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 8px;
    }
    > .rightBottom {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
      position: absolute;
      bottom: 48px;
      right: 8px;
    }
  }
}
