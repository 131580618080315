@import '@/styles/common.scss';

.container {
  > .map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
