@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  gap: 6px;
  right: 8px;
  bottom: 8px;

  > .group {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    background-color: $topbar-color;
    overflow: hidden;

    > .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 32px;
      color: lightgray;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        color: white;
        background-color: darken($topbar-color, 5%);
      }
      &.active {
        color: $tint-color;
      }
    }
    > .zoom {
      width: 36px;
      font-size: 12px;
      color: white;
      text-align: center;
    }
  }
}
