@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 8px 0;

  > .gauges {
    display: flex;
    flex-direction: column;
    gap: 4px;

    > .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      > .label {
        width: 96px;
        color: white;
        font-size: 10px;
        text-align: right;
      }
      > .gauge {
        flex: 1;
        position: relative;
        height: 16px;
        padding: 1px;
        border: 1px solid $line-color;
        border-radius: 4px;
        box-sizing: border-box;

        > .bar {
          height: 100%;
          border-radius: 2px;
          background-color: yellowgreen;
        }
        > .value {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 8px;
          color: white;
          transform: translate(-50%, -50%);
        }
        > .caution {
          position: absolute;
          top: 0;
          width: 0.1px;
          height: 100%;

          &.v50 {
            left: 50%;
            background-color: orange;
          }
          &.v80 {
            left: 80%;
            background-color: red;
          }
        }
      }
    }
  }
  > .table {
    display: flex;
    flex-direction: column;
    gap: 6px;

    > .header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;

      > .title {
        font-size: 10px;
        font-weight: bold;
        color: white;
      }
    }
    > table {
      border-top: 0.5px solid $line-color;

      > tbody > tr {
        height: 18px;

        > td {
          font-size: 9px;
          color: white;
          padding: 0 4px;
          border-bottom: 0.5px solid $line-color;

          &:nth-child(2) {
            text-align: center;
          }
        }
      }
    }
  }
}
