@import '@/styles/common.scss';

.container {
  width: 226px;
  padding: 8px;
  border-radius: 4px;
  background-color: rgba(#111111, 0.9);

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .name {
      color: white;
      font-size: 13px;
      font-weight: 600;
    }
    > .total {
      color: white;
      font-size: 11px;
    }
  }
  > .body {
    @include scrollable;
    margin-top: 8px;
    border-radius: 4px;
    background-color: rgba(white, 0.05);
    height: 96px;

    > .empty {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    > .row {
      display: flex;
      align-items: center;
      gap: 6px;
      height: 24px;
      padding: 0 6px;
      border-bottom: 0.1px dashed $line-color;

      > .color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: gray;
      }
      > .name {
        flex: 1;
        color: gray;
        font-size: 11px;
        line-height: 1.4;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.active {
          color: white;
        }
      }
      > .button {
        cursor: pointer;
      }
    }
  }
}
