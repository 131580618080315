@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 12px 0 16px;
    background-color: rgba(black, 0.9);

    > .title {
      color: white;
      font-size: 13px;
      font-weight: 600;
    }
    > .info {
      display: flex;
      gap: 12px;

      > .item {
        color: white;
        font-size: 12px;

        > span {
          font-size: 10px;
        }
      }
    }
  }
  > .list {
    @include scrollable;
    overflow-y: scroll;
    max-height: calc(100vh - 380px);
    background-color: rgba(black, 0.6);

    > .wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 8px 6px 8px 8px;
    }
  }
}
