@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 4px;
  background-color: $topbar-color;
  overflow: hidden;
}
