@import '@/styles/common.scss';

.container {
  position: relative;
  align-self: center;

  > .thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: white;
    font-size: 14px;
    border-radius: 50%;
    background-color: $tint-color;
    cursor: pointer;
  }
  > .panel {
    display: none;
    flex-direction: column;
    gap: 12px;
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    min-width: 224px;
    padding: 16px;
    border-radius: 4px;
    background-color: rgba(black, 0.9);
    z-index: 20;

    &.show {
      display: flex;
    }
    > .top {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > .name {
        font-size: 16px;
        color: white;
      }
      > .email {
        font-size: 12px;
        color: darkgray;
      }
    }
    > .line {
      border-top: 0.1px solid $line-color;
    }
    > .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;

      > .button {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        cursor: pointer;
      }
    }
  }
}
