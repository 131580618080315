@import '@/styles/common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  > .dim {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(black, 0.5);
    z-index: 40;
  }
  > .modal {
    padding: 24px 32px;
    background-color: $modal-color;
    border-radius: 4px;
    overflow: hidden;
    z-index: 50;
    opacity: 0;
    transform: scale(0);
    transition: all 0.2s;

    &.show {
      opacity: 1;
      transform: scale(1);
    }
  }
}
