@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  > .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;

    &.blink {
      animation: blink 1s ease-in-out infinite;
    }
    > .value {
      font-size: 11px;
    }
  }
}

@keyframes blink {
  50% {
    color: red;
  }
}
