@import '@/styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .panel {
    display: flex;
    flex-direction: column;
    background-color: rgba(black, 0.5);
    border-radius: 2px;
    padding: 6px 8px;
    font-size: 10px;
    color: white;
  }
}
